import { signIn } from "next-auth/react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { withError } from "@applied-ai/utils";
import { Backdrop, Spinner } from "@applied-ai/components";
import { useEffect } from "react";
import { useRouter } from "next/router";

const Index = () => {
  const router = useRouter();

  useEffect(() => {
    const callbackUrl = router.query.redirectUrl
      ? (router.query.redirectUrl as string)
      : "/";
    signIn("zitadel", { callbackUrl });
  }, [router.query.redirectUrl]);

  return (
    <Backdrop open={true}>
      <Spinner color="secondary" size="large" />
    </Backdrop>
  );
};

export const getServerSideProps = withError(async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale || "en", ["common"])),
    },
  };
});

export default Index;
